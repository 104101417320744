import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import { AuthService } from 'src/app/auth.service'
import { Router } from '@angular/router'
import { ConstantsService } from 'src/app/services/constants/constants.service'

@Component({
  selector: 'cui-topbar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class TopbarUserMenuComponent {
  badgeCount: number
  name: string = ''
  role: string = ''
  email: string = ''
  phone: string = ''
  user: any
  datasavetype: string = '1'
  companyId: any
  test: any
  constructor(
    private store: Store<any>,
    private globals: ConstantsService,
    private Auth: AuthService,
    private router: Router,
  ) {
    // this.store.pipe(select(Reducers.getUser)).subscribe(state => {
    //   this.name = state.name
    //   this.role = state.role
    //   this.email = state.email
    // })
    // this.companyId = JSON.parse(localStorage.getItem('companyId'))
    // this.store.pipe(select(Reducers.getUser)).subscribe(state => {

    this.email = globals.email

    // this.datasavetype = localStorage.getItem('datasavetype')
  }

  // badgeCountIncrease() {
  //   this.badgeCount = this.badgeCount + 1
  // }

  loginfo
  CompanyId: any
  StoreId: any
  ngOnInit(): void {
    this.test = JSON.parse(localStorage.getItem('users'))
    const user = JSON.parse(localStorage.getItem('users'))
    console.log(user)
    this.name = user?.name
    this.phone = user?.phoneNo
    // this.CompanyId = user[0].companyId
    // console.log(this.CompanyId)
    // this.Auth.getloginusers(this.companyId).subscribe(data =>{
    //   this.logusers = data
    //   console.log(this.logusers)
    //   this.name = this.logusers?.name
    //   this.phone = this.logusers?.phoneNumber

    // })
  }
  logusers: any
  // getCom() {
  //   this.Auth.getloginusers(this.companyId).subscribe(data =>{
  //     this.logusers = data
  //     console.log(this.logusers)
  //     this.name = this.logusers?.name
  //     this.phone = this.logusers?.phoneNumber

  //   })
  // }

  logout() {
    localStorage.setItem('logState', 'logged_out')
    this.store.dispatch(new UserActions.Logout())
    localStorage.removeItem('users')
  }

  lock() {
    localStorage.removeItem('users')
    console.log(localStorage.removeItem('users'))
    this.router.navigateByUrl('/auth/pinscreen')
  }
}
