<div>
  <!-- <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu"> -->
    <!-- <i class="icon fe fe-bell"></i> -->
  <!-- </div> -->
  <!-- <nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="card cui__utils__shadow width-350">
      <div class="card-body p-0">
        <kit-list-2></kit-list-2>
      </div>
    </div>
  </nz-dropdown-menu> -->
</div> 