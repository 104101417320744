<div class="card-body position-relative overflow-hidden">
  <div class="font-size-36 font-weight-bold text-dark mb-n2">
    <label *ngFor="let pay of dashboard">{{pay.outcome}}</label>
  </div>
  <div class="text-uppercase">Outcome</div>
  <div class="chartContainer">
    <!-- <x-chartist [data]="chartData" [type]="chartOptions.type" [options]="chartOptions.options"
      [responsiveOptions]="chartOptions.responsiveOptions" [events]="chartOptions.events" class="ct-hidden-points">
    </x-chartist> -->
  </div>
</div>